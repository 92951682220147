/*======================================
//--//-->   Navbar
======================================*/
.navbar {
  height: 100px;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}  
.nav-bar {
  width: auto;
}  
.copyright-navbar {
  display: none;
}
.navbar-mobile {
  display: none;
}
.social-links-navbar {
  ul {
    list-style: none;
  }
}
/*======================================
//--//-->   Dropdown
======================================*/
.navbar-desktop {
  .dropdown {
    list-style: none;
    &:hover {
      background-color: #F4F5F6;
      border-radius: 4px;
      transition: ease-in .1s;
      .dropdown-menu {
        display: block;
        position: absolute;
        border: none;
        transform: translate3d(5px, 26px, 0px);
        top: 9px;
        left: -4px;
        .dropdown-item {
          &:hover {
            background-color: #F4F5F6;
          }
          &:active {
            color: #02499a;
          }
        }
      }
    }
  }
  .nav-link {
    &:hover {
      background-color: #F4F5F6;
      border-radius: 4px;
      transition: ease-in .1s;
    }
  }
}
/*======================================
//--//-->   Hamburger Navbar
======================================*/
.navbar-toggler {
  border: 1px solid #4AB7C1;
  height: 60px;
  position: relative;
  &:focus, &:active {
    outline: 0;
  }
  span {
    display: block;
    background-color: #4AB7C1;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
    &:nth-child(1), &:nth-child(3) {
      transition: -webkit-transform .3s ease-in-out;
      transition: transform .3s ease-in-out;
      transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    }
  }
  &:not(.collapsed) span {
    &:nth-child(1) {
      position: absolute;
      left: 12px;
      top: 10px;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      opacity: 0.9;
      margin-top: 18px;
    }
    &:nth-child(2) {
      height: 12px;
      visibility: hidden;
      background-color: transparent;
    }
    &:nth-child(3) {
      position: absolute;
      left: 12px;
      top: 10px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      opacity: 0.9;
      margin-top: 18px;
    }
  }
}
@media (max-width: 992px) {
  .nav-bar {
    width: 100%;
    margin-top: 11.5px;
    margin-bottom: 11.5px;
  }  
  .navbar-mobile {
    display: contents;
    .dropdown {
      list-style: none;
      .dropdown-menu {
        border: none;
        top: 6px;
        left: -4px;
        .dropdown-item {
          &:active {
            color: #02499a;
          }
        }
      }
    }
    .nav-link {
      &:active, &:visited, &:focus {
        background-color: #F4F5F6 !important;
        border-radius: 4px;
        transition: ease-in .35s;
      }
    }
  }
  .navbar-desktop {
    display: none !important;
  }
  .social-links-navbar {
    display: none !important;
  }
  .social-links-navbar-mobile {
    ul {
      list-style: none;
    }
  }
  #navbarDefault {
    background-color: #ffffff;
    height: 100vh;
    width: 100vh;
    z-index: 1;
  }
  .copyright-navbar {
    display: block;
  }
}