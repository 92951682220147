.table-of-contents {
  width: 100%;
  overflow-y: auto;
  border: 1px solid #4AB7C1;
  border-radius: 0px;
  margin-bottom: 48px;
  &.visible-toc {
    ul {
      display: block;
    }
    .toggle-toc {
      &::after {
        transform: rotate(180deg);
        transition: rotate .2s;
      }
    }
  }
  ul {
    display: none;
  }
  .toggle-toc {
    font-size: 20px;
    font-weight: bold;
    color: #4AB7C1;
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    display: block;
    &:hover {
      text-decoration: none;
      opacity: 0.6;
      transition: opacity .2s;
    }
    &::after {
      content: "";
      width: 30px;
      height: 30px;
      background: url(/assets/images/icons/nav-caret.svg);
      display: inline-block;
      position: absolute;
      right: 55px;
    }
  }
}
nav[data-toggle=toc] .nav {
	padding-bottom: 20px;
}
nav[data-toggle=toc] .nav>li>a {
	display: block;
	padding: 8px 40px;
	font-size: 18px;
  line-height: 1.5;
	font-weight: 500;
	color: #2E4058;
}
nav[data-toggle=toc] .nav>li>a:focus,
nav[data-toggle=toc] .nav>li>a:hover {
	color: #4AB7C1;
	text-decoration: none;
  transition: ease-in .2s;
}
nav[data-toggle=toc] .nav .nav>li>a {
	padding-left: 80px;
  &::before {
    content: "•";
    width: 30px;
    height: 30px;
    display: inline-block;
    position: absolute;
    left: 76px;
  }
}