/*======================================
//--//-->  Post body
======================================*/
#post-cover {
    img {
        width: 100%;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
}
.post-wrapper {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 48px;
        margin-top: 96px;
        font-weight: bold;
        color: #2E4058;
    }
    p, ul, ol, dl {
        margin-bottom: 48px;
        line-height: 1.6;
        font-size: 20px;
        color: #3b424d;
    }
    a {
        color: #2E9FAD;
        &:hover {
            transition: ease-in .2s;
            color: #007585;
            text-decoration: none;
        }
    }
    li {
        margin-bottom: 16px;
    }
    img {
        max-width: 825px !important;
        margin-top: 36px;
    }
    blockquote {
        background-color: #F7F7F7;
        padding: 42px 42px 6px 42px;
        border-left: 2px solid #2E9FAD;
        margin-bottom: 48px;
        p {
            color: #212529;
        }
    }
    table {
        width: 100%;
        border-bottom: 4px solid #969696;
        margin-bottom: 48px;
        font-size: 18px;
        thead {
            background-color: #EBF6F7;
            border-bottom: solid 4px #2E9FAD;
            th {
                color: #2E9FAD;
                padding: 20px 40px;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #969696;
                &:nth-of-type(even) {
                    background-color: #F7F7F7;
                  }
                td, th {
                    padding: 20px 40px;
                }
            }
        }
    }
}
.user-image {
    border-radius: 50%;
}
/*======================================
//--//-->  Post Thumb
======================================*/
.image-thumb {
    position: relative;
    width: 260px;
    object-fit: cover;
    height: 164px;
    overflow: hidden;
    .category-post-thumb {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
    img {
        border-style: none;
        vertical-align: middle;
    }
}
.category-thumb {
    color: #2E9FAD;
    background-color: #FFFFFF;
    border-radius: 0px;
    font-size: 13px;
    letter-spacing: .5px;
    padding: 4px 8px;
    text-transform: uppercase;
    font-weight: bold;
}
/*======================================
//--//-->  Post Vertical
======================================*/
.image-thumb-vertical {
    position: relative;
    width: 100%;
    object-fit: cover;
    height: 164px;
    overflow: hidden;
    .category-post-thumb-vertical {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
    img {
        border-style: none;
        vertical-align: middle;
      }
}
.category-thumb-vertical {
    color: #2E9FAD;
    background-color: #FFFFFF;
    border-radius: 0px;
    font-size: 13px;
    letter-spacing: .5px;
    padding: 4px 8px;
    text-transform: uppercase;
    font-weight: bold;
}
/*======================================
//--//-->  Post New
======================================*/
.image-thumb-new {
    position: relative;
    width: 100%;
    object-fit: cover;
    height: 369px;
    overflow: hidden;
    .category-post-thumb {
        position: absolute;
        top: 24px;
        left: 24px;
        z-index: 1;
    }
    .title-post-text {
      position: absolute;
        bottom: 24px;
        left: 24px;
        z-index: 1;
    }
    .image-new {
        &::before {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
        }
        &:hover {
          opacity: 0.8;
          transition: ease-in .2s;
        }
        img {
        border-style: none;
        vertical-align: middle;
        }
    }
}
/*======================================
//--//-->  Breakpoints
======================================*/
@media (max-width: 1200px) {
    .image-thumb-new {
      height: 388px;
    }
}
@media (max-width: 992px) {
    .image-thumb-new {
      height: 369px;
    }
}
@media (max-width: 576px) {
    .image-thumb-new {
        height: 260px;
        .category-post-thumb {
          top: 10px;
          left: 10px;
        }
        .title-post-text {
          bottom: 16px;
          left: 10px;
        }
    } 
    .image-thumb {
      width: 100%;
    }
}