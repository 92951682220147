.pagination {
    font-size: 18px;
    border-radius: 0px;
    .page-item {
      font-weight: bold;
      margin: 0px 8px;
        .page-link {
            padding: 10px 16px;
            color: #2E9FAD;
            border: 1px solid #2E9FAD;
            &:first-child {
            border-radius: 0px;
            }
            &:last-child {
            border-radius: 0px;
            }
            &:hover {
            background-color: #d1f6fa;
            transition: ease-in .2s;
            }
        }
    }
    .active {
        background-color: #2E9FAD;
        color: #FFFFFF;
        border: 1px solid #2E9FAD;
        &:hover {
          background-color: #2E9FAD;
        }
        .page-link {
            background-color: #2E9FAD;
            color: #FFFFFF;
            border: 1px solid #2E9FAD;
            &:hover {
              background-color: #2E9FAD;
            }
        }
    }
    .disabled {
        .page-link {
            color: #2E9FAD;
            border: 1px solid #2E9FAD;
            opacity: 0.3;
        }
    }

}

@media (max-width: 480px) {
    .pagination {
        .page-item {
            margin: 0px 5px;
            .page-link {
                padding: 6px 10px;
            }
        }
        
    }
}