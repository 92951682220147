#disqus_thread {
    overflow: hidden;
    overflow-x: hidden;
  
    iframe {
      margin-bottom: -54px;
    }
  
    iframe[src*=ads-iframe] {
      display: none !important;
      content-visibility: hidden;
    }
  
    iframe[src*=ads] {
      display: none !important;
      content-visibility: hidden;
    }
  
    iframe:not([src]) {
      display: none !important;
      content-visibility: hidden;
    }
  }
  
  iframe[src*=taboola] {
    display: none !important;
    content-visibility: hidden;
  }
  
  iframe[src*=ads] {
    display: none !important;
    content-visibility: hidden;
  }
  
  iframe[src*=ads-iframe] {
    display: none !important;
    content-visibility: hidden;
  }
  
  #taboola, #ad-container {
    display: none !important;
    content-visibility: hidden;
  }
  