/*======================================
//--//-->  Fonts Sizes and Weights
======================================*/
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-48 {
  font-size: 48px;
}
.font-weight-medium {
  font-weight: 700;
}
/*======================================
//--//-->  Colors: Backgrounds, Texts and Fills
======================================*/
.text-dark-blue {
  color: #2E4058;
}
.text-green {
  color: #2E9FAD;
}
.bg-dark-blue {
  background-color: #031D3B;
}
.bg-light-green {
  background-color: #EBF6F7;
}
.bg-facebook {
  background-color: #3C589A;
}
.bg-twitter {
  background-color: #29ACEF;
}
.fill-green {
  fill: #2E9FAD;
}
.fill-dark-blue {
  fill: #2E4058;
}
.fill-light-gray {
  fill: #d6d6d6;
}
/*======================================
//--//-->  Line Height and Spaces
======================================*/
.line-height-12 {
  line-height: 1.2;
}
.line-height-13 {
  line-height: 1.3;
}
.line-height-14 {
  line-height: 1.4;
}
.letter-spacing-5 {
  letter-spacing: .5px;
}
/*======================================
//--//-->  Borders
======================================*/
.border-green {
  border: 1px solid #4AB7C1 !important;
}
.border-bottom-green {
  border-bottom: 1px solid #4AB7C1;
}
.border-top-green {
  border-top: 1px solid #4AB7C1;
}
.border-radius-0 {
  border-radius: 0px;
}
.border-radius-4 {
  border-radius: 4px;
}
/*======================================
//--//-->  Buttons
======================================*/
.btn-green {
  background-color: #2E9FAD;
  padding: 10px 15px;
  color: #FFFFFF;
  border-radius: 0px;
  &:hover {
    opacity: .6;
    transition: ease-in .2s;
    color: #FFFFFF;
  }
}
.btn-outline-green {
  border: 1px solid #2E9FAD;
  color: #2E9FAD;
  padding: 10px 15px;
  border-radius: 0px;
  &:hover {
    border: 1px solid #2E9FAD;
    background-color: #2E9FAD;
    color: #FFFFFF;
    transition: ease-in .2s;
  }
}
/*======================================
//--//-->  Margins and Paddings
======================================*/
.mb-20 {
  margin-bottom: 20px;
}
.mb-38 {
  margin-bottom: 38px;
}
.mb-40 {
  margin-bottom: 40px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
/*======================================
//--//-->  Hovers
======================================*/
:hover {
  transition: ease-in .2s;
}
.hover-green {
  &:hover {
    color: #2E9FAD !important;
    text-decoration: none;
    transition: ease-in .2s;
  }
}
.hover-white {
  &:hover {
    color: #2E9FAD!important;
    text-decoration: none;
  }
}
.focus {
  &:focus, &:active {
    background-color: #EBF6F7;
    transition: ease-in .1s;
  }
}
.icon-hover {
  opacity: 100%;
  &:hover {
    opacity: 70%;
    transition: opacity .2s;
  }
}
/*======================================
//--//-->  Others
======================================*/
.decoration-none {
  &:hover {
    text-decoration: none;
  }
}
.list-style-none {
  list-style: none; 
}
.d-none-last-child {
  &:last-child {
    display: none;
  }
}
.box-shadow { 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
}